import moment from 'moment';

export function routeChangeFlag(state = null, action) {
	switch(action.type) {
		case 'SET_ROUTE_CHANGE_FLAG':
			return action.flag;
		default:
			return state;
	}
}

export function historyListener(state = null, action) {
	switch(action.type) {
		case 'ADD_HISTORY_LISTENER':
			return action.listener;
		default:
			return state;
	}
}

export function workTypes(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_WORK_TYPES':
			return action.types;
		default:
			return state;
	}
}

export function retailPrices(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_RETAIL_PRICES':
			return action.prices;
		default:
			return state;
	}
}

export function weeklyAppointmentCount(state = 0, action) {
	switch(action.type) {
		case 'RECEIVE_WEEKLY_APPOINTMENT_COUNT':
			return action.count;
		default:
			return state;
	}
}

export function appointmentCount(state = 0, action) {
	switch(action.type) {
		case 'RECEIVE_APPOINTMENT_COUNT':
			return action.count;
		default:
			return state;
	}
}

export function newLeadCount(state = 0, action) {
	switch(action.type) {
		case 'RECEIVE_NEW_LEAD_COUNT':
			return action.count;
		default:
			return state;
	}
}

export function corporateQuotes(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_CORPORATE_QUOTES':
			return action.quotes;
		default:
			return state;
	}
}


// vans

export function vans(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_VANS':
			return action.vans;
		default:
			return state;
	}
}

let initDate = moment(new Date()).format("YYYY-MM-DD");
export function availableDate(state = initDate, action) {
	switch(action.type) {
		case 'ADD_AVAILABLE_DATE':
			return action.date;
		default:
			return state;
	}
}

export function payrollReport(state = null, action) {
	switch(action.type) {
		case 'RECEIVE_PAYROLL_REPORT':
			return action.report;
		default:
			return state;
	}
}

export function currentShift(state = null, action) {
	switch(action.type) {
		case 'RECEIVE_SHIFT':
			return action.shift;
		default:
			return state;
	}
}

export function singleUser(state = null, action) {
	switch(action.type) {
		case 'RECEIVE_SINGLE_USER':
			return action.user;
		default:
			return state;
	}
}

export function userRoles(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_USER_ROLES':
			return action.roles;
		default:
			return state;
	}
}

export function users(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_USERS':
			return action.users;
		default:
			return state;
	}
}

export function corporateInvoicePdf(state = null, action) {
	switch(action.type) {
		case 'RECEIVE_CORPORATE_INVOICE_PDF':
			return action.data;
		default:
			return state;
	}
}

export function corporateInvoice(state = {}, action) {
	switch(action.type) {
		case 'RECEIVE_CORPORATE_INVOICE':
			return action.data;
		default:
			return state;
	}
}

export function invoiceStatusCodes(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_INVOICE_STATUS_CODES':
			return action.data;
		default:
			return state;
	}
}


export function invoices(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_INVOICES':
			return action.data;
		default:
			return state;
	}
}


export function singleInvoice(state = {}, action) {
	switch(action.type) {
		case 'RECEIVE_SINGLE_INVOICE':
			return action.data;
		default:
			return state;
	}
}


/*
* Timer.
*/

export function timerStart(state = [], action) {
	switch(action.type) {
		case 'START_TIMER':
			return { ...action.time };
		default:
			return state;
	}
}

export function timerEnd(state = [], action) {
	switch(action.type) {
		case 'STOP_TIMER':
			return { ...action.time };
		default:
			return state;
	}
}

export function timerState(state = [], action) {
	switch(action.type) {
		case 'SET_TIMER_STATE':
			return action.state;
		default:
			return state;
	}
}

/*
* Survey and raffles.
*/

export function raffleWinner(state = {}, action) {
	switch(action.type) {
		case 'RECEIVE_RAFFLE_WINNER':
			return action.winner;
		default:
			return state;
	}
}


export function surveyResponses(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_SURVEY_RESPONSES':
			return action.responses;
		default:
			return state;
	}
}


/* requested dates for corporate lead group */
export function requestedDates(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_REQUESTED_DATES':
			//console.log("RECEIVE_REQUESTED_DATES", action);
			return action.dates;
		default:
			return state;
	}
}

/* this represents the default page and page size of ALL paginated endpoints */
const initialPageInfo = { current_page: 1, current_size: 50 };
export function pageInfo(state = initialPageInfo, action) {
	switch(action.type) {
		case 'CHANGE_PAGE':
			//console.log("CHANGE_PAGE red", action);
			return {
				...action.page
			}
		default:
			return state;
	}
}

/* user login */
export function userDetails(state = [], action) {
	switch(action.type) {
		case 'STORE_USER_DETAILS':
			//console.log("STORE_USER_DETAILS", action);
			return action.details;
		default:
			return state;
	}
}


/* corporate leads */
export function corporateLeadGroup(state = [], action) {
	//console.log("corporateLeadGroup reducer", action);

	switch(action.type) {
		case 'RECEIVE_CORPORATE_LEAD_GROUP':
			return action.group || {};
		default:
			return state;
	}
}

export function corporateLeads(state = [], action) {
	//console.log("corporateLeads", action);

	switch(action.type) {
		case 'RECEIVE_CORPORATE_LEADS_DIGEST':
			return action.leads || {};
		default:
			return state;
	}
}

/* corporate quote reducer functions */

export function vehicleYears(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_VEHICLE_YEARS':
			return action.year;
		default:
			return state;
	}
}

export function vehicleMakes(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_VEHICLE_MAKES':
			return action.make;
		default:
			return state;
	}
}

export function vehicleModels(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_VEHICLE_MODELS':
			return action.model;
		default:
			return state;
	}
}

export function vehicleTrims(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_VEHICLE_TRIMS':
			return action.trim;
		default:
			return state;
	}
}


/* other */

export function toast(state = [], action) {
	//console.log("toast reducer", action);
	switch(action.type) {
		case 'MAKE_TOAST':
			return action.toast;
		default:
			return state;
	}
}


export function workOrderPage(state = 1, action) {
	//console.log("workOrderPage reducer", action);
	switch(action.type) {
		case 'CHANGE_WORK_ORDER_PAGE':
			return action.page
		default:
			return state;
	}
}


export function singleAppointment(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_SINGLE_APPOINTMENT':
			return action.apt
		default:
			return state;
	}

}


let defaultView = "day";
export function calendarView(state = defaultView, action) {
	//console.log('reducer calendarView', action);
	switch(action.type) {
		case 'CHANGE_CALENDAR_VIEW':
			return action.view;
		default:
			return state;
	}
}

export function currentCustomer(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_SINGLE_CUSTOMER':
			return {
				...action.json
			}
		default:
			return state;
	}
}

export function customers(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_CUSTOMERS_DIGEST':
			//console.log("RECEIVE_CUSTOMERS_DIGEST", action);
			return [
				...action.json
			]
		default:
			return state;
	}
}

export function corporateCustomers(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_CORPORATE_CUSTOMERS':
			//console.log("RECEIVE_CUSTOMERS_DIGEST", action);
			return action.customers;
		default:
			return state;
	}
}

export function errors(state = [], action) {
	switch(action.type) {
		case 'TRACK_REQUEST_ERROR':
			//console.log("TRACK_REQUEST_ERROR", action);
			return [
				action
			]
		default:
			return state;
	}
}

let defaultDate = new Date();

export function currentDate(state = defaultDate, action) {

	switch(action.type) {
		case 'CHANGE_DATE':
		//	console.log("currentDate change", action);
			return action.date;
		default:
			return state;
	}
}

// the default shop is 1 - "come to you"
// 2 - "calgary shop"
let defaultShop = 2;

export function currentShop(state = defaultShop, action) {

	switch(action.type) {
		case 'CHANGE_CURRENT_SHOP':
			// console.log("currentShop change", action.shop);
			return action.shop;
		default:
			return state;
	}
}


export function currentTimeSlot(state = {}, action) {
	switch(action.type) {
		case 'CHANGE_TIME_SLOT':
		//console.log("currentTimeSlot change", action);
			return action.slot;
		default:
			return state;
	}
}


export function timeslots(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_TIME_SLOTS':
			//console.log("RECEIVE_TIME_SLOTS", action);
			//console.log("RECEIVE_TIME_SLOTS", action.slot);
			return action.slot;
		default:
			return state;
	}
}


export function allTimeslots(state = [], action) {
	switch(action.type) {
		case 'RECEIVE_ALL_TIME_SLOTS':
			//console.log("RECEIVE_ALL_TIME_SLOTS", action);
			return action.slot;
		default:
			return state;
	}
}


export function shops(state = [], action) {
	//console.log("shops", action);

	switch(action.type) {
		case 'RECEIVE_SHOPS':
			//console.log('*** RECEIVE_SHOPS');
			return [
				...action.json
			]
		default:
			return state;
	}
}


export function appointments(state = [], action) {
	//console.log("reducer appointments", action);

	switch(action.type) {
		case 'REQUEST_APPOINTMENTS':
			return [
				...state
			]
		case 'RECEIVE_APPOINTMENTS':
			return [
				...action.data
			]
		default:
			return state;
	}
}


export function singleLead(state = [], action) {
	//console.log("singleLead", action);

	switch(action.type) {
		case 'INSPECT_LEAD':
			return [
				...state,
				{
					lead: action.json
				}
			]
		case 'RECEIVE_SINGLE_LEAD':
			//console.log('RECEIVE_SINGLE_LEAD');
			return {
				...action.json
			}
		case 'CLAIM_LEAD':
			return state;
		default:
			return state;
	}
}


export function leads(state = [], action) {
	//console.log("leads reducer", action);
	switch (action.type) {
		case 'ADD_LEAD':
			return [
				...state,
				{
				  lead: action.lead
				}
			]
		case 'CLEAR_ALL_LEADS':
			return []
		case 'RECEIVE_LEADS_DIGEST':
			// console.log('RECEIVE_LEADS_DIGEST', action.leads);
			return [
				...action.leads
			]
		case 'REQUEST_LEADS_DIGEST':
			return state
		default:
			return state
	}
}

const defaultStartDate = moment(new Date()).startOf('month').subtract(2, 'weeks').toDate();
// console.log("defaultStartDate", defaultStartDate);
// const defaultStartDate = new Date(new Date().getFullYear(), 0, 1);
// const defaultStartDate = new Date();
export function startDate(state = defaultStartDate, action) {
	switch (action.type) {
		case 'ADD_START_DATE':
			// console.log("startDate", action.date);
			return action.date
		default:
			return state
	}
}

const defaultEndDate = moment(new Date()).endOf('month').add(2, 'weeks').toDate();
// console.log("defaultEndDate", defaultEndDate);
// const defaultEndDate = new Date(new Date().getFullYear(), 11, 31);
// const defaultEndDate = new Date();
export function endDate(state = defaultEndDate, action) {
	switch (action.type) {
		case 'ADD_END_DATE':
			// console.log("endDate", action.date);
			return action.date
		default:
			return state
	}
}


export function filter(state = [], action) {
	switch (action.type) {
		case 'CHANGE_FILTER':
			return action.filter;
		default:
			return state;
	}
}
