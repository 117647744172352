
export function receiveNewLeadCount(count) {
	return {
		type: 'RECEIVE_NEW_LEAD_COUNT',
		count
	}
}

export function receiveCorporateLeadsDigest(data) {
	// console.log("receiveLeadsDigest", data);
	return {
		type: "RECEIVE_CORPORATE_LEADS_DIGEST",
		leads: data,
		receivedAt: Date.now()
	}
}

export function clearLeads() {
	//console.log("clearLeads");
	return {
		type: 'CLEAR_ALL_LEADS'
	}
}

export function addLead(lead) {
	return {
		type: 'ADD_LEAD',
		lead
	}
}

export function addStartDate(date) {
	//console.log("addStartDate");
	return {
		type: 'ADD_START_DATE',
		date
	}
}

export function addEndDate(date) {
	//console.log("addEndDate");
	return {
		type: 'ADD_END_DATE',
		date
	}
}

export function changeFilter(filter) {
	//console.log("changeFilter");
	return {
		type: 'CHANGE_FILTER',
		filter
	}
}

export function requestLeadsDigest(data) {
	//console.log("requestLeadsDigest");
	return {
		type: "REQUEST_LEADS_DIGEST",
		data
	}
}

/*export function receivePageChange(links) {
	return {
		type: "CHANGE_PAGE",
		links
	}
}*/

export function receiveLeadsDigest(data) {
	//console.log("receiveLeadsDigest", data, json);
	return {
		type: "RECEIVE_LEADS_DIGEST",
		leads: data,
		receivedAt: Date.now()
	}
}

export function inspectLead(data, json) {
	//console.log("inspectLead", json);
	return {
		type: "INSPECT_LEAD",
		json
	}
}

export function receiveSingleLead(data, json) {
	return {
		type: "RECEIVE_SINGLE_LEAD",
		json
	}
}

export function claimLead(data) {
	return {
		type: "CLAIM_LEAD",
		data
	}
}
