import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router'

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

import { getLeads, getLeadInspectData } from '../actions/LeadDigestActions';
import { getToken, clearToken } from '@iso/lib/helpers/utility';

// middleware for react-router-redux
const history = require("history").createBrowserHistory();
const customMiddleware = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware, customMiddleware];

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(rootReducer, bindMiddleware(middlewares));

sagaMiddleware.run(rootSaga);
export { store, history };
