import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Mails from '@iso/redux/mail/reducer';
import Calendar from '@iso/redux/calendar/reducer';
import Box from '@iso/redux/box/reducer';
import Notes from '@iso/redux/notes/reducer';
import Todos from '@iso/redux/todos/reducer';
import Contacts from '@iso/redux/contacts/reducer';
import Cards from '@iso/redux/card/reducer';
import Chat from '@iso/redux/chat/reducers';
import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Invoices from '@iso/redux/invoice/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import YoutubeSearch from '@iso/redux/youtubeSearch/reducers';
import Articles from '@iso/redux/articles/reducers';
import Investors from '@iso/redux/investors/reducers';
import scrumBoard from '@iso/redux/scrumBoard/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';
import profile from '@iso/redux/profile/reducer';
import githubSearch from '@iso/redux/githubSearch/reducers';
import quiz from '@iso/redux/quiz/reducer';

// custom reducers
import { leads, startDate, endDate, filter, singleLead, appointments
	, shops, errors, currentShop, calendarView, customers, timeslots
	, currentTimeSlot, currentDate, singleAppointment, workOrderPage
	, toast, vehicleMakes, vehicleModels, vehicleTrims, vehicleYears
	, corporateLeads, corporateLeadGroup, userDetails, currentCustomer
	, pageInfo, requestedDates, surveyResponses, raffleWinner
	, timerStart, timerEnd, timerState, invoices, invoiceStatusCodes
	, corporateInvoice, singleInvoice, corporateInvoicePdf, users
	, userRoles, singleUser, currentShift, payrollReport
	, corporateCustomers, allTimeslots, availableDate
	, vans, corporateQuotes, newLeadCount, appointmentCount
	, workTypes, retailPrices, historyListener
	, routeChangeFlag, weeklyAppointmentCount } from '../reducers/Reducer.js';

const history = require("history").createBrowserHistory();

export const reducers = {
  router: connectRouter(history),
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Calendar,
  Invoices,
  modal,
  startDate,
  endDate,
  filter,
  toast,
  leads,
  singleLead,
  appointments,
  shops,
  errors,
  currentShop,
  customers,
  timeslots,
  currentTimeSlot,
  currentDate,
  calendarView,
  singleAppointment,
  workOrderPage,
  vehicleMakes,
  vehicleModels,
  vehicleTrims,
  vehicleYears,
  corporateLeads,
  corporateLeadGroup,
  userDetails,
  currentCustomer,
  pageInfo,
  requestedDates,
  surveyResponses,
  raffleWinner,
  timerStart,
  timerState,
  timerEnd,
  invoices,
  invoiceStatusCodes,
  corporateInvoice,
  corporateInvoicePdf,
  singleInvoice,
  users,
  userRoles,
  singleUser,
  currentShift,
  payrollReport,
  corporateCustomers,
  allTimeslots,
  availableDate,
  vans,
  corporateQuotes,
  newLeadCount,
  appointmentCount,
  workTypes,
  retailPrices,
  historyListener,
  routeChangeFlag,
  weeklyAppointmentCount
};

export default combineReducers(reducers);
