

export function setRouteChangeFlag(flag) {
	return {
		type: 'SET_ROUTE_CHANGE_FLAG',
		flag
	}
}

export function addHistoryListener(listener) {
	return {
		type: 'ADD_HISTORY_LISTENER',
		listener
	}
}

export function changeDate(date) {
	return {
		type: 'CHANGE_DATE',
		date
	}
}

export function addAvailableDate(date) {
	return {
		type: 'ADD_AVAILABLE_DATE',
		date
	}
}

export function clearPersistData(data) {
	return {
		type: 'CLEAR_PERSIST_DATA'
	}
}

export function changeCurrentShop(shop) {
	return {
		type: 'CHANGE_CURRENT_SHOP',
		shop
	}
}

/*
* Actions for the Timer component.
*/
export function startTimer(time) {
	return {
		type: 'START_TIMER',
		time
	}
}

export function stopTimer(time) {
	return {
		type: 'STOP_TIMER',
		time
	}
}

export function setTimerState(state) {
	return {
		type: 'SET_TIMER_STATE',
		state
	}
}

/* other */

export function addStartDate(date) {
	//console.log("change page action", page);
	return {
		type: 'ADD_START_DATE',
		date
	}
}


export function addEndDate(date) {
	return {
		type: 'ADD_END_DATE',
		date
	}
}


export function changePage(page) {
	//console.log("change page action", page);
	return {
		type: 'CHANGE_PAGE',
		page
	}
}


export function storeUserDetails(details) {
	// console.log("storeUserDetails");
	return {
		type: 'STORE_USER_DETAILS',
		details
	}
}


export function makeToast(msg, variant, show) {
	let toast = { message: msg, variant: variant, show: show };
	
	return {
		type: 'MAKE_TOAST',
		toast
	}
}

