import React, { Component } from 'react';
import './Toast.css';

export default function Toast(props) {
	return props.show ? (
		<div className="toastWrapper">
			<div id="toastContent" className={ `toastStyle ${props.variant} ${props.type || 'floating'}` }>
				{ props.message }
			</div>
		</div>
	) : ""
}
