import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { store } from './redux/store';
import { connectRouter } from 'connected-react-router';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import { PersistGate } from 'redux-persist/integration/react'
import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { rootReducer, reducers } from './redux/root-reducer'
import './App.css';

const persistConfig = {
  key: 'root',
  whitelist: ['userDetails', 'currentCustomer', 'timerStart'
				, 'timerEnd', 'timerState', 'pageInfo', 'historyListener'],
  storage,
}

/*
	for persistence of user details like credentials this uses react-persist.
    it replaces the built-in redux store in the template with the persisted root-reducer
*/
const persistedReducer = persistCombineReducers(persistConfig, reducers);
const appReducer = (state, action) => {
	// console.log("appReducer", state, action);
	if(action.type === 'CLEAR_PERSIST_DATA' || action.type === 'persist/PURGE') {
	    console.log("clearing persist data");
		if(state.historyListener) {
			// console.log("Stopping historyListener");
			state.historyListener(); // stop listener
            state.historyListener = null; // set to null, force creation of a new listener
		}
		state = {
			_persist: {
				version: -1,
				rehydrated: true
			}
		};
	}
    // console.log(state);
	return persistedReducer(state, action)
}
store.replaceReducer(appReducer);
const persistor = persistStore(store);

const App = () => (
  <Provider store={store}>
	<PersistGate loading={null} persistor={persistor}>
	  <AppProvider>
	    <>
		  <GlobalStyles />
		  <Routes persistor={persistor} />
	    </>
	  </AppProvider>
	</PersistGate>
  </Provider>
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
